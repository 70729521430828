@forward '@nebular/theme/styles/theming';
@use '@nebular/theme/styles/theming' as *;
@use '@nebular/theme/styles/themes/dark';

// @nebular theming framework
@import '@nebular/theme/styles/theming';
// @nebular out of the box themes
@import '@nebular/theme/styles/themes';
$nb-enable-css-custom-properties: true;
$nb-themes: nb-register-theme(
  (
    sidebar-width: 125px,
    sidebar-shadow: 0px,
    sidebar-padding: 0px,
    sidebar-background-color: 'transparent',
    actions-icon-color: var(--color-primary-500),
    layout-padding: 16px,
    // paleta (ref: figma)
    color-primary-100: #f1fcc9,
    color-primary-200: #e0f995,
    color-primary-300: #c5ef5f,
    color-primary-400: #a8e038,
    color-primary-500: #80cc00,
    color-primary-600: #67af00,
    color-primary-700: #509200,
    color-primary-800: #3c7600,
    color-primary-900: #2e6100,

    color-secondary-blue-100: #ccd3ff,
    color-secondary-blue-200: #99a8ff,
    color-secondary-blue-300: #677cfe,
    color-secondary-blue-400: #3350fe,
    color-secondary-blue-500: #0125fe,
    color-secondary-blue-600: #011ecb,
    color-secondary-blue-700: #011698,
    color-secondary-blue-800: #000f66,
    color-secondary-blue-900: #000733,

    color-secondary-aqua-100: #e8fafd,
    color-secondary-aqua-200: #b9eff9,
    color-secondary-aqua-300: #8ae4f4,
    color-secondary-aqua-400: #5bd9f0,
    color-secondary-aqua-500: #2cceec,
    color-secondary-aqua-600: #13b5d3,
    color-secondary-aqua-700: #0f8ca4,
    color-secondary-aqua-800: #0b6475,
    color-secondary-aqua-900: #063c46,

    color-secondary-violet-100: #e4d6ff,
    color-secondary-violet-200: #c1a3ff,
    color-secondary-violet-300: #9e6fff,
    color-secondary-violet-400: #7c3dff,
    color-secondary-violet-500: #5a0aff,
    color-secondary-violet-600: #4600d6,
    color-secondary-violet-700: #3500a3,
    color-secondary-violet-800: #250070,
    color-secondary-violet-900: #14003d,

    color-success-100: #e9fdda,
    color-success-200: #cefbb6,
    color-success-300: #aaf490,
    color-success-400: #87e972,
    color-success-500: #55db46,
    color-success-600: #35bc33,
    color-success-700: #239d2a,
    color-success-800: #167f25,
    color-success-900: #0d6921,

    color-info-100: #f2f8ff,
    color-info-200: #c7e2ff,
    color-info-300: #94cbff,
    color-info-400: #42aaff,
    color-info-500: #0095ff,
    color-info-600: #006fd6,
    color-info-700: #0057c2,
    color-info-800: #0041a8,
    color-info-900: #002885,

    color-warning-100: #fffdf2,
    color-warning-200: #fff1c2,
    color-warning-300: #ffe59e,
    color-warning-400: #ffc94d,
    color-warning-500: #ffaa00,
    color-warning-600: #db8b00,
    color-warning-700: #b86e00,
    color-warning-800: #945400,
    color-warning-900: #703c00,

    color-danger-100: #fff2f2,
    color-danger-200: #ffd6d9,
    color-danger-300: #ffa8b4,
    color-danger-400: #ff708d,
    color-danger-500: #ff3d71,
    color-danger-600: #db2c66,
    color-danger-700: #b81d5b,
    color-danger-800: #94124e,
    color-danger-900: #700940,

    color-basic: #fcfcfc,
    color-basic-100: #e7e7e4,
    color-basic-200: #cececa,
    color-basic-300: #b6b6af,
    color-basic-400: #9d9d95,
    color-basic-500: #85857a,
    color-basic-600: #6a6a62,
    color-basic-700: #979797,
    color-basic-800: #353531,
    color-basic-900: #192038,
    color-basic-1000: #151a30,

    // theming
    user-initials-text-color: #fff,
    background-basic-color-1: #f5f5f5,
    layout-background-color: #f5f5f5,
    card-background-color: color-secondary,
    header-background-color: color-basic-800,
    header-text-color: color-primary-500,
    actions-icons-color: color-basic-500,
  ),
  'Apex',
  default
);

// Helper Clasess

.bg-primary {
  background-color: nb-theme(color-primary-500)
}
.bg-secondary-aqua {
  background-color: nb-theme(color-secondary-aqua-500)
}
.bg-secondary-blue {
  background-color: nb-theme(color-secondary-blue-500)
}
.bg-secondary-violet {
  background-color: nb-theme(color-secondary-violet-500)
}
.bg-info {
  background-color: nb-theme(color-info-500)
}
.bg-warning {
  background-color: nb-theme(color-warning-500)
}
.bg-danger {
  background-color: nb-theme(color-danger-500)
}
.bg-basic {
  background-color: nb-theme(color-basic-500)
}

nb-icon{
  display: flex;
  align-items: center;
}
